import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <img src="./working_for_azathoth.png" alt="display" style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} />
    </div>
  );
}

export default App;
